import { Injectable, Injector } from '@angular/core';
import { User } from './models';
import { UserService as SharedUserService } from '@shared/user/user.service';

@Injectable()
export class UserService extends SharedUserService {
  constructor(
    protected injector: Injector
  ) {
    super(injector);

    this.model = User;
  }
}
