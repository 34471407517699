import { PermissionGroup } from './group';

export class Permission {
  public name: string;
  public groups: Array<PermissionGroup>;

  constructor(permission: any = {}) {
    this.name = permission.name;
    this.groups = permission.groups || [];
  }
}
