import * as lodash from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomSelectValue } from '../customSelect/models';
import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class FiltersService {
  private changeEventEmitter: EventEmitter<void>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.changeEventEmitter = new EventEmitter();
  }

  public parseFromQueryParams(): Object {
    let filters = {};

    if (typeof this.activatedRoute.snapshot.queryParams.filters !== 'undefined') {
      filters = JSON.parse(this.activatedRoute.snapshot.queryParams.filters);
    }

    return filters;
  }

  public filtersChanged(filters: Object, key: string, value: CustomSelectValue): Promise<void> {
    filters[key] = value.id;
    filters = lodash.pickBy(filters, (filter, filterKey) => {
      return lodash.identity(filter) && filterKey !== 'toJSON';
    });

    let params = { ...this.activatedRoute.snapshot.queryParams };
    if (lodash.isEmpty(filters)) {
      if (params.hasOwnProperty('filters')) {
        delete params.filters;
      }
    } else {
      params = { ...params, ...{ filters: JSON.stringify(filters) } };
    }

    return this.router
      .navigate([], { queryParams: params })
      .then(() => {
        this.changeEventEmitter.emit();
      });
  }

  public getChangeEventEmitter(): EventEmitter<void> {
    return this.changeEventEmitter;
  }
}
