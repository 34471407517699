import { ApiModule } from '../../../core/api/api.module';
import { NgModule } from '@angular/core';
import { StaffService } from './staff.service';

@NgModule({
  imports: [
    ApiModule
  ],
  providers: [
    StaffService
  ]
})
export class AccountSharedStaffModule { }
