import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { PropertyService } from '../property.service';

@Injectable()
export class PropertyExistsGuard implements CanActivate {
  constructor(
    private propertyService: PropertyService,
    private router: Router
  ) { }

  public async canActivate(): Promise<boolean> {
    const selectedProperty = await this.propertyService.getSelectedProperty();

    if (!selectedProperty || !selectedProperty.id) {
      this.router.navigate(['/setup/identification']);
    }

    return !!selectedProperty;
  }
}
