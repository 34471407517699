import { ApiModule } from '../../core/api/api.module';
import { LoginService } from './login.service';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    ApiModule
  ],
  providers: [
    LoginService
  ]
})
export class LoginModule { }
