import { ApiModule } from '@core/api/api.module';
import { CarepackageGroupService } from './carepackageGroup.service';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    ApiModule
  ],
  providers: [
    CarepackageGroupService
  ]
})
export class AccountSharedCarepackageGroupModule { }
