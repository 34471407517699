import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { toastAnimation } from './toast.animation';

@Component({
  selector: '[toast-component]',
  templateUrl: './toast.html',
  styleUrls: ['./toast.scss'],
  animations: toastAnimation
})
export class ToastComponent extends Toast {
  constructor(
    public toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }
}
