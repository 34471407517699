import * as lodash from 'lodash';
import * as moment from 'moment';
import { Component } from '@angular/core';
import { NgForage } from 'ngforage';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.html'
})
export class AppComponent {
  private languages: Array<string>;

  constructor(
    private translateService: TranslateService,
    private storageService: NgForage
  ) {
    import('../assets/languages.json')
      .then((result) => {
        this.languages = lodash.map(result['default'], 'id');
        this.translateService.addLangs(this.languages);

        return this.storageService.getItem('user');
      })
      .then((user) => {
        const language = (user) ? user['language'] : this.translateService.getBrowserLang();
        const defaultLang = (language && lodash.includes(this.languages, language)) ? language : 'en';

        this.translateService.setDefaultLang(defaultLang);
        moment.locale(defaultLang);
      });
  }
}
