import { ApiModule } from '@core/api/api.module';
import { NgModule } from '@angular/core';
import { PropertyService } from './property.service';

@NgModule({
  imports: [
    ApiModule
  ],
  providers: [
    PropertyService
  ]
})
export class AdminSharedPropertyModule { }
