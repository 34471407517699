import { ApiModule } from '../../core/api/api.module';
import { BusinessTypeService } from './businessType.service';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    ApiModule
  ],
  providers: [
    BusinessTypeService
  ]
})
export class BusinessTypeModule { }
