import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DpDatePickerModule } from 'ng2-date-picker';
import { DragulaModule } from 'ng2-dragula';
import { FormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgForageConfig } from 'ngforage';
import { NgModule } from '@angular/core';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxPhoneSelectModule } from 'ngx-phone-select';
import { NotFoundPageModule } from './shared/notFound/notFound.module';
import { TagInputModule } from 'ngx-chips';
import { ToastComponent } from './core/toast/toast.component';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { WebpackTranslateLoader } from './app.translate.loader';

@NgModule({
  declarations: [
    AppComponent,
    ToastComponent
  ],
  imports: [
    AppRoutingModule,
    DpDatePickerModule,
    DragulaModule,
    FormsModule,
    GooglePlaceModule,
    NgProgressModule,
    NgProgressRouterModule,
    NgxPhoneSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    }),
    ToastrModule.forRoot({
      toastComponent: ToastComponent
    }),
    BrowserAnimationsModule,
    TagInputModule,
    NgxPaginationModule,
    NotFoundPageModule
  ],
  entryComponents: [
    ToastComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(ngfConfig: NgForageConfig) {
    ngfConfig.configure({
      name: 'PMS'
    });
  }
}
