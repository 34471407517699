import { Account } from './models';
import { ApiService } from '../../core/api/api.service';
import { Injectable } from '@angular/core';

@Injectable()
export class LoginService {
  constructor(
    private apiService: ApiService
  ) { }

  public login(account: Account): Promise<any> {
    console.log(account);
    return this.apiService.post('/auth', account);
  }
}
