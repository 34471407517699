import { ApiService } from '@core/api/api.service';
import { CarepackageGroup, CarepackageGroupFilters } from './models';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Property } from '../property/models';

@Injectable()
export class CarepackageGroupService {
  constructor(
    private apiService: ApiService
  ) { }

  public search(filters: CarepackageGroupFilters): Observable<Array<CarepackageGroup>> {
    return this.apiService.get<Array<CarepackageGroup>>('/carepackage-groups', { filters: filters });
  }
  public searchCarepackageGroups(filters: CarepackageGroupFilters, property: Property): Observable<Array<CarepackageGroup>> {
    const params = this.prepareParams(property, filters);

    return this.apiService.get<Array<CarepackageGroup>>('/carepackage-groups', params);
  }

  public get(id: number): Observable<CarepackageGroup> {
    return this.apiService
      .get<CarepackageGroup>(`/carepackage-groups/${id}`)
      .pipe(
        map((response) => new CarepackageGroup(response))
      );
  }

  public create(carepackageGroup: CarepackageGroup): Promise<any> {
    return this.apiService.post('/carepackage-groups', carepackageGroup);
  }

  public update(carepackageGroup: CarepackageGroup): Promise<any> {
    return this.apiService.put(`/carepackage-groups/${carepackageGroup.id}`, carepackageGroup);
  }

  public delete(carepackageGroup: CarepackageGroup): Promise<any> {
    return this.apiService.delete(`/carepackage-groups/${carepackageGroup.id}`);
  }

  public getAll<T>(selectedProperty: Property, filters?: CarepackageGroupFilters, page?: number, pageSize?: number): Observable<T> {
    const params = this.prepareParams(selectedProperty, filters, page, pageSize);

    return this.apiService
      .get<any>('/carepackage-groups', params)
      .pipe(
        map((response) => {
          return (page) ? response : response.map((carepackageGroup) => new CarepackageGroup(carepackageGroup));
        })
      );
  }

  private prepareParams(
    selectedProperty: Property,
    filters: CarepackageGroupFilters,
    page?: number,
    pageSize?: number,
    orderBy: string = '-pk'
  ): Object {
    const params: any = {
      order_by: orderBy,
      filters: {},
      nested_filters: {
        carepackage_set: {
          property: selectedProperty.id
        }
      }
    };

    if (filters) {
      if (filters.searchText) {
        params.filters['name__icontains'] = filters.searchText;
      }
    }

    if (page) {
      params.page = page;
    }

    if (pageSize) {
      params.page_size = pageSize;
    }

    return params;
  }
}
