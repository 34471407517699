import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from '../user.service';

@Injectable()
export class RegistrationCompletedGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  public async canActivate(): Promise<boolean> {
    const user = await this.userService.get();
    const registrationCompleted = user && user.registration_completed;

    if (!registrationCompleted) {
      this.router.navigate(['/accomodation/complete']);
    }

    return registrationCompleted;
  }
}
