import { ApiService } from '@core/api/api.service';
import { Injectable } from '@angular/core';
import { NgForage } from 'ngforage';
import { Observable } from 'rxjs';
import { PaginatedResponse } from '@shared/pagination/models';
import { Property, PropertyFilters } from './models';
import { map } from 'rxjs/operators';

@Injectable()
export class PropertyService {
  constructor(
    private apiService: ApiService,
    private storageService: NgForage
  ) { }

  public getAll(page?: number, pageSize?: number, filters?: PropertyFilters): Observable<PaginatedResponse<Property>> {
    const params = this.prepareParams(page, pageSize, filters);

    return this.apiService.get<PaginatedResponse<Property>>('/properties', params);
  }

  public get(id: number): Observable<Property> {
    return this.apiService
      .get<Property>(`/properties/${id}`)
      .pipe(
        map((response) => new Property(response))
      );
  }

  public updateServiceFee(property: Property): Promise<void> {
    return this.apiService.put(`/properties/${property.id}`, {
      service_fee: property.service_fee,
      service_fee_unit: property.service_fee_unit,
    });
  }

  public setSelectedProperty(property: Property): Promise<any> {
    return this.storageService.setItem('selectedProperty', {
      id: property.id
    });
  }

  private prepareParams(page?: number, pageSize?: number, filters?: PropertyFilters, orderBy: string = '-pk'): Object {
    const params: any = {
      order_by: orderBy
    };

    if (page) {
      params.page = page;
    }

    if (pageSize) {
      params.page_size = pageSize;
    }

    if (filters) {
      params.filters = {};

      if (filters.searchText) {
        params.filters.or = [
          { name__icontains: filters.searchText },
          { email__icontains: filters.searchText },
          { phone__icontains: filters.searchText },
          { user__first_name__icontains: filters.searchText },
          { user__last_name__icontains: filters.searchText }
        ];
      }

      if (filters.countryId) {
        params.filters.country = filters.countryId;
      }
    }

    return params;
  }
}
