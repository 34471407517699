import * as moment from 'moment';
import { DifferencePipe } from 'ngx-moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe extends DifferencePipe implements PipeTransform {
  public transform(
    startDate: Date | moment.Moment,
    endDate: Date | moment.Moment,
    unit?: moment.unitOfTime.Diff,
    precision?: boolean
  ): number {
    return Math.abs(super.transform(moment(startDate), moment(endDate), unit, precision)) + 1;
  }
}
