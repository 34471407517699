import * as lodash from 'lodash';
import { ApiService } from '../../core/api/api.service';
import { configuration } from '../../../configurations/configuration';
import { Group } from '../group/models';
import { Injectable } from '@angular/core';
import { Permission, PermissionGroup } from './models';

@Injectable()
export class PermissionService {
  constructor(
    private apiService: ApiService
  ) { }

  public bulkUpdate(permission: string, group: PermissionGroup): Promise<any> {
    return this.apiService.put('/groups/permissions', {
      permissions: configuration.user.permissions[permission][group.name].map((codename) => {
        return {
          codename: codename,
          group_id: group.id,
          has: group.has
        };
      })
    });
  }

  public getByConfiguration(groupsFromServer: Array<Group>): Array<Permission> {
    const permissions = [];

    lodash.forOwn(configuration.user.permissions, (groups, permissionName) => {
      const permission = new Permission({
        name: permissionName,
        groups: []
      });

      lodash.forOwn(groups, (permissions, groupName) => {
        const group = lodash.find(groupsFromServer, { name: groupName });
        if (group) {
          let has = false;
          if (permissions.length > 0) {
            has = permissions.every((value) => !!lodash.find(group.permissions, { codename: value }));
          }

          permission.groups.push(new PermissionGroup({
            id: group.id,
            name: group.name,
            has: has,
            hidden: permissions.length === 0
          }));
        }
      });

      const allGroupsAreHidden = permission.groups.every((group) => group.hidden);
      if (!allGroupsAreHidden) {
        permission.groups = lodash.orderBy(permission.groups, ['id'], ['asc']);
        permissions.push(permission);
      }
    });

    return permissions;
  }
}
