import { ApiModule } from '../api/api.module';
import { AuthService } from './auth.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ApiModule
  ],
  providers: [
    AuthService
  ]
})
export class AuthModule { }
