import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { WebpackTranslateLoader } from '@app/app.translate.loader';
import { AccountSharedExpediaLabelComponent } from './expediaLabel.component';

@NgModule({
  declarations: [
    AccountSharedExpediaLabelComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    })
  ],
  exports: [
    AccountSharedExpediaLabelComponent
  ]
})
export class AccountSharedExpediaLabelModule { }
