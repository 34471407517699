import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const toastAnimation = [
  trigger('flyInOut', [
    state('inactive', style({
      display: 'none',
      opacity: 0,
    })),
    state('active', style({})),
    state('removed', style({ opacity: 0 })),
    transition('inactive => active',
      animate('300ms ease-in')
    ),
    transition('active => removed',
      animate('300ms ease-in'),
    ),
  ])
];
