import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from '../user.service';

@Injectable()
export class IsNotSuperUserGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  public async canActivate(): Promise<boolean> {
    const user = await this.userService.get();

    if (user) {
      if (user.is_superuser) {
        this.router.navigate(['/admin']);
      }

      return !user.is_superuser;
    } else {
      return true;
    }
  }
}
