import { ApiService } from '@core/api/api.service';
import { Discount, DiscountSearchFilter } from './models';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Property } from '../property/models';
import { PropertyService } from '../property/property.service';

@Injectable()
export class DiscountService {
  constructor(
    private apiService: ApiService,
    private propertyService: PropertyService
  ) { }

  public search(filters: DiscountSearchFilter): Observable<Array<Discount>> {
    const params = this.prepareParams(filters);

    return this.apiService.get<Array<Discount>>('/discounts', params);
  }

  public getAll(selectedProperty: Property): Observable<Array<Discount>> {
    const params = this.prepareParams(null, selectedProperty);

    return this.apiService
      .get<Array<Discount>>('/discounts', params)
      .pipe(
        map((discounts) => discounts.map((discount) => new Discount(discount)))
      );
  }

  public async create(discount: Discount, isGlobal: boolean = true): Promise<any> {
    if (!isGlobal) {
      const selectedProperty = await this.propertyService.getSelectedProperty();
      discount.property_id = selectedProperty.id;
    }

    return this.apiService.post('/discounts', discount);
  }

  public update(discount: Discount): Promise<any> {
    return this.apiService.put(`/discounts/${discount.id}`, discount);
  }

  public delete(discount: Discount): Promise<any> {
    return this.apiService.delete(`/discounts/${discount.id}`);
  }

  private prepareParams(filters: DiscountSearchFilter, property?: Property): Object {
    const params: any = {
      filters: { }
    };

    if (filters) {
      if (filters.isActive) {
        params.filters.is_active = filters.isActive;
      }

      if (filters.condition) {
        params.filters.condition = filters.condition;
      }

      if (filters.conditionPromo) {
        params.filters.condition_promo__icontains = filters.conditionPromo;
      }
    }

    if (property) {
      params.filters.or = [
        { property: property.id },
        { property: null }
      ];
    }

    return params;
  }
}
