import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys'
})
export class KeysPipe implements PipeTransform {
  public transform(value: Object): Array<string> {
    if (value) {
      return Object.keys(value);
    }
  }
}
