import { ApiModule } from '../../core/api/api.module';
import { NgModule } from '@angular/core';
import { UserService } from './user.service';

@NgModule({
  imports: [
    ApiModule
  ],
  providers: [
    UserService
  ]
})
export class UserModule { }
