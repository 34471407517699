import * as moment from 'moment';

export const configuration = {
  production: false,
  apiUrl: 'http://172.232.45.22/api',
  // apiUrl: 'http://127.0.0.1:8003/api',
  supportEmail: 'support@365daybookings.com',
  requestTimeout: 30000,
  oauth: {
    modal: {
      width: 640,
      height: 640
    }
  },
  filesFields: ['files', 'identity_document_front', 'identity_document_back', 'additional_identity_document'],
  stripe: {
    defaultCountry: 'US',
    apiPublishableKey: 'pk_test_GrDMrmz6XC6MVPuEHOyXBVlx',
    supportedCountries: [
      'AU', 'AT', 'BE', 'CA', 'DK', 'FI', 'FR', 'DE', 'HK', 'IE', 'JP', 'LU',
      'NL', 'NZ', 'NO', 'ES', 'SE', 'CH', 'GB', 'US', 'IT', 'PT'
    ],
    countriesWithState: ['AU', 'CA', 'IE', 'JP', 'US'],
    supportedCurrencies: {
      'AU': ['AUD'],
      'AT': ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'CHF'],
      'BE': ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'CHF'],
      'CA': ['CAD', 'USD'],
      'DK': ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'CHF'],
      'FI': ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'CHF'],
      'FR': ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'CHF'],
      'DE': ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'CHF'],
      'HK': ['HKD'],
      'IE': ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'CHF'],
      'IT': ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'CHF'],
      'JP': ['JPY'],
      'LU': ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'CHF'],
      'NL': ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'CHF'],
      'NZ': ['NZD'],
      'NO': ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'CHF'],
      'ES': ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'CHF'],
      'SE': ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'CHF'],
      'CH': ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'CHF'],
      'GB': ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'CHF'],
      'US': ['USD'],
      'PT': ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'CHF']
    },
    minUserAge: 14,
    fees: {
      'AUD': {
        'domestic': {
          'countries': ['AU'],
          'percent': 1.75,
          'fixed': 0.30
        },
        'international': {
          'percent': 2.90,
          'fixed': 0.30
        }
      },
      'EUR': {
        'domestic': {
          'countries': ['AT', 'BE', 'FI', 'FR', 'DE', 'IE', 'IT', 'LU', 'NL', 'PT', 'ES'],
          'percent': 1.40,
          'fixed': 0.25
        },
        'international': {
          'percent': 2.90,
          'fixed': 0.25
        }
      },
      'DKK': {
        'domestic': {
          'countries': ['DK'],
          'percent': 1.40,
          'fixed': 1.80
        },
        'international': {
          'percent': 2.90,
          'fixed': 1.80
        }
      },
      'GBP': {
        'domestic': {
          'countries': ['UK'],
          'percent': 1.40,
          'fixed': 0.20
        },
        'international': {
          'percent': 2.90,
          'fixed': 0.20
        }
      },
      'NOK': {
        'domestic': {
          'countries': ['NO'],
          'percent': 2.40,
          'fixed': 0.02
        },
        'international': {
          'percent': 2.90,
          'fixed': 0.02
        }
      },
      'SEK': {
        'domestic': {
          'countries': ['SE'],
          'percent': 1.40,
          'fixed': 1.80
        },
        'international': {
          'percent': 2.90,
          'fixed': 1.80
        }
      },
      'USD': {
        'domestic': {
          'countries': ['US'],
          'percent': 2.90,
          'fixed': 0.30
        },
        'international': {
          'percent': 3.90,
          'fixed': 0.30
        }
      },
      'CHF': {
        'domestic': {
          'countries': ['CH'],
          'percent': 2.90,
          'fixed': 0.30
        },
        'international': {
          'percent': 2.90,
          'fixed': 0.30
        }
      },
      'CAD': {
        'domestic': {
          'countries': ['CA'],
          'percent': 2.90,
          'fixed': 0.30
        },
        'international': {
          'percent': 3.50,
          'fixed': 0.30
        }
      },
      'HKD': {
        'domestic': {
          'countries': ['HK'],
          'percent': 3.40,
          'fixed': 2.35
        },
        'international': {
          'percent': 3.40,
          'fixed': 2.35
        }
      },
      'JPY': {
        'domestic': {
          'countries': ['JP'],
          'percent': 3.60,
          'fixed': 0.00
        },
        'international': {
          'percent': 3.60,
          'fixed': 0.00
        }
      },
      'NZD': {
        'domestic': {
          'countries': ['NZ'],
          'percent': 2.90,
          'fixed': 0.30
        },
        'international': {
          'percent': 2.90,
          'fixed': 0.30
        }
      },
      'SGD': {
        'domestic': {
          'countries': ['SG'],
          'percent': 3.40,
          'fixed': 0.50
        },
        'international': {
          'percent': 3.40,
          'fixed': 0.50
        }
      }
    },
    stripeCountryBankAccountRequirements: {
      'AU': {
        'AUD': ['AU']
      },
      'AT': {
        'EUR': ['AT', 'BE', 'DK', 'FI', 'FR', 'DE', 'IE', 'IT', 'LU', 'NL', 'NO', 'PT', 'ES', 'SE', 'CH', 'GB'],
        'DKK': ['DK'],
        'GBP': ['GB'],
        'NOK': ['NO'],
        'SEK': ['SE'],
        'USD': ['US'],
        'CHF': ['CH']
      },
      'BE': {
        'EUR': ['AT', 'BE', 'DK', 'FI', 'FR', 'DE', 'IE', 'IT', 'LU', 'NL', 'NO', 'PT', 'ES', 'SE', 'CH', 'GB'],
        'DKK': ['DK'],
        'GBP': ['GB'],
        'NOK': ['NO'],
        'SEK': ['SE'],
        'USD': ['US'],
        'CHF': ['CH']
      },
      'CA': {
        'CAD': ['CA'],
        'USD': ['CA', 'US']
      },
      'DK': {
        'EUR': ['AT', 'BE', 'DK', 'FI', 'FR', 'DE', 'IE', 'IT', 'LU', 'NL', 'NO', 'PT', 'ES', 'SE', 'CH', 'GB'],
        'DKK': ['DK'],
        'GBP': ['GB'],
        'NOK': ['NO'],
        'SEK': ['SE'],
        'USD': ['US'],
        'CHF': ['CH']
      },
      'FI': {
        'EUR': ['AT', 'BE', 'DK', 'FI', 'FR', 'DE', 'IE', 'IT', 'LU', 'NL', 'NO', 'PT', 'ES', 'SE', 'CH', 'GB'],
        'DKK': ['DK'],
        'GBP': ['GB'],
        'NOK': ['NO'],
        'SEK': ['SE'],
        'USD': ['US'],
        'CHF': ['CH']
      },
      'FR': {
        'EUR': ['AT', 'BE', 'DK', 'FI', 'FR', 'DE', 'IE', 'IT', 'LU', 'NL', 'NO', 'PT', 'ES', 'SE', 'CH', 'GB'],
        'DKK': ['DK'],
        'GBP': ['GB'],
        'NOK': ['NO'],
        'SEK': ['SE'],
        'USD': ['US'],
        'CHF': ['CH']
      },
      'DE': {
        'EUR': ['AT', 'BE', 'DK', 'FI', 'FR', 'DE', 'IE', 'IT', 'LU', 'NL', 'NO', 'PT', 'ES', 'SE', 'CH', 'GB'],
        'DKK': ['DK'],
        'GBP': ['GB'],
        'NOK': ['NO'],
        'SEK': ['SE'],
        'USD': ['US'],
        'CHF': ['CH']
      },
      'HK': {
        'HKD': ['HK']
      },
      'IE': {
        'EUR': ['AT', 'BE', 'DK', 'FI', 'FR', 'DE', 'IE', 'IT', 'LU', 'NL', 'NO', 'PT', 'ES', 'SE', 'CH', 'GB'],
        'DKK': ['DK'],
        'GBP': ['GB'],
        'NOK': ['NO'],
        'SEK': ['SE'],
        'USD': ['US'],
        'CHF': ['CH']
      },
      'IT': {
        'EUR': ['AT', 'BE', 'DK', 'FI', 'FR', 'DE', 'IE', 'IT', 'LU', 'NL', 'NO', 'PT', 'ES', 'SE', 'CH', 'GB'],
        'DKK': ['DK'],
        'GBP': ['GB'],
        'NOK': ['NO'],
        'SEK': ['SE'],
        'USD': ['US'],
        'CHF': ['CH']
      },
      'JP': {
        'JPY': ['JP']
      },
      'LU': {
        'EUR': ['AT', 'BE', 'DK', 'FI', 'FR', 'DE', 'IE', 'IT', 'LU', 'NL', 'NO', 'PT', 'ES', 'SE', 'CH', 'GB'],
        'DKK': ['DK'],
        'GBP': ['GB'],
        'NOK': ['NO'],
        'SEK': ['SE'],
        'USD': ['US'],
        'CHF': ['CH']
      },
      'LN': {
        'EUR': ['AT', 'BE', 'DK', 'FI', 'FR', 'DE', 'IE', 'IT', 'LU', 'NL', 'NO', 'PT', 'ES', 'SE', 'CH', 'GB'],
        'DKK': ['DK'],
        'GBP': ['GB'],
        'NOK': ['NO'],
        'SEK': ['SE'],
        'USD': ['US'],
        'CHF': ['CH']
      },
      'NZ': {
        'NZD': ['NZ']
      },
      'NO': {
        'EUR': ['AT', 'BE', 'DK', 'FI', 'FR', 'DE', 'IE', 'IT', 'LU', 'NL', 'NO', 'PT', 'ES', 'SE', 'CH', 'GB'],
        'DKK': ['DK'],
        'GBP': ['GB'],
        'NOK': ['NO'],
        'SEK': ['SE'],
        'USD': ['US'],
        'CHF': ['CH']
      },
      'ES': {
        'EUR': ['AT', 'BE', 'DK', 'FI', 'FR', 'DE', 'IE', 'IT', 'LU', 'NL', 'NO', 'PT', 'ES', 'SE', 'CH', 'GB'],
        'DKK': ['DK'],
        'GBP': ['GB'],
        'NOK': ['NO'],
        'SEK': ['SE'],
        'USD': ['US'],
        'CHF': ['CH']
      },
      'SE': {
        'EUR': ['AT', 'BE', 'DK', 'FI', 'FR', 'DE', 'IE', 'IT', 'LU', 'NL', 'NO', 'PT', 'ES', 'SE', 'CH', 'GB'],
        'DKK': ['DK'],
        'GBP': ['GB'],
        'NOK': ['NO'],
        'SEK': ['SE'],
        'USD': ['US'],
        'CHF': ['CH']
      },
      'CH': {
        'EUR': ['AT', 'BE', 'DK', 'FI', 'FR', 'DE', 'IE', 'IT', 'LU', 'NL', 'NO', 'PT', 'ES', 'SE', 'CH', 'GB'],
        'DKK': ['DK'],
        'GBP': ['GB'],
        'NOK': ['NO'],
        'SEK': ['SE'],
        'USD': ['US'],
        'CHF': ['CH']
      },
      'GB': {
        'EUR': ['AT', 'BE', 'DK', 'FI', 'FR', 'DE', 'IE', 'IT', 'LU', 'NL', 'NO', 'PT', 'ES', 'SE', 'CH', 'GB'],
        'DKK': ['DK'],
        'GBP': ['GB'],
        'NOK': ['NO'],
        'SEK': ['SE'],
        'USD': ['US'],
        'CHF': ['CH']
      },
      'US': {
        'USD': ['US']
      },
      'PT': {
        'EUR': ['AT', 'BE', 'DK', 'FI', 'FR', 'DE', 'IE', 'IT', 'LU', 'NL', 'NO', 'PT', 'ES', 'SE', 'CH', 'GB'],
        'DKK': ['DK'],
        'GBP': ['GB'],
        'NOK': ['NO'],
        'SEK': ['SE'],
        'USD': ['US'],
        'CHF': ['CH']
      },
    },
    bankAccountFields: {
      AU: ['account_number', 'bsb'],
      AT: ['iban'],
      BE: ['iban'],
      CA: ['account_number', 'transit_number', 'institution_number'],
      DK: ['iban'],
      FI: ['iban'],
      FR: ['iban'],
      DE: ['iban'],
      HK: ['account_number', 'clearing_code', 'branch_code'],
      IE: ['iban'],
      IT: ['iban'],
      JP: ['account_number', 'bank_code', 'branch_code'],
      LU: ['iban'],
      NL: ['iban'],
      NZ: ['account_number'],
      NO: ['iban'],
      ES: ['iban'],
      SE: ['iban'],
      CH: ['iban'],
      GB: ['account_number', 'sort_code'],
      US: ['account_number', 'routing_number'],
      PT: ['iban']
    }
  },
  nonStripePaymentMethods: {
    bankAccountFields: ['iban', 'account_number', 'routing_number']
  },
  expedia: {
    includeBedTypes: [
      'bunkBed', 'fullBed', 'futon', 'kingBed', 'queenBed', 'murphyBed',
      'twinBed', 'twinXLBed', 'trundleBed', 'waterBed', 'sofaBed'
    ],
    extraBedTypes: ['dayBed', 'crib', 'rollawayBed', 'sofaBed'],
    bedTypesWithSizes: {
      bunkBed: ['Full', 'King', 'Queen', 'Twin', 'TwinXL'],
      fullBed: ['Full'],
      futon: ['Full', 'King', 'Queen', 'Twin', 'TwinXL'],
      kingBed: ['King'],
      queenBed: ['Queen'],
      murphyBed: ['Full', 'King', 'Queen', 'Twin', 'TwinXL'],
      twinBed: ['Twin'],
      twinXLBed: ['TwinXL'],
      trundleBed: ['Full', 'King', 'Queen', 'Twin', 'TwinXL'],
      waterBed: ['Full', 'King', 'Queen', 'Twin', 'TwinXL'],
      sofaBed: ['Full', 'King', 'Queen', 'Twin', 'TwinXL'],
      dayBed: ['Full', 'King', 'Queen', 'Twin', 'TwinXL'],
      crib: ['Crib'],
      rollawayBed: ['Full', 'King', 'Queen', 'Twin', 'TwinXL']
    },
    requirements: {
      property: {
        requirementFields: ['description', 'reservation_manager_id', 'alternate_reservation_manager_id', 'payment_account_id'],
        minFacilitiesCount: 10,
        minImagesCount: 3
      }
    }
  },
  cancellationPolicies: {
    maxCount: 2
  },
  toast: {
    closeButton: true,
    timeOut: 4000,
    extendedTimeOut: 2000,
    positionClass: 'toast-bottom-right'
  },
  swiper: {
    default_swiper_config: {
      direction: 'horizontal',
      watchOverflow: true,
      spaceBetween: 7,
      slidesPerView: 1,
      observer: true,
    }
  },
  modal: {
    theme: 'plain'
  },
  calendar: {
    boxSizePx: 50
  },
  property: {
    defaultArrivalTime: moment().set({ hours: 12, minutes: 0 })
  },
  user: {
    groups: ['owner', 'admin', 'manager', 'staff'],
    permissions: {
      view_calendar_bookings: {
        owner: ['READ_ACCOUNT_BOOKING', 'READ_ACCOUNT_TAX'],
        admin: ['READ_ACCOUNT_BOOKING', 'READ_ACCOUNT_TAX'],
        manager: ['READ_PROPERTY_BOOKING', 'READ_PROPERTY_TAX'],
        staff: []
      },
      add_edit_bookings: {
        owner: ['CREATE_ACCOUNT_BOOKING', 'EDIT_ACCOUNT_BOOKING'],
        admin: ['CREATE_ACCOUNT_BOOKING', 'EDIT_ACCOUNT_BOOKING'],
        manager: ['CREATE_PROPERTY_BOOKING', 'EDIT_PROPERTY_BOOKING'],
        staff: []
      },
      view_folios: {
        owner: ['CREATE_ACCOUNT_BOOKING', 'EDIT_ACCOUNT_BOOKING'],
        admin: ['READ_ACCOUNT_FOLIO', 'READ_ACCOUNT_FOLIO_TRANSACTION'],
        manager: ['READ_PROPERTY_FOLIO', 'READ_PROPERTY_FOLIO_TRANSACTION'],
        staff: []
      },
      add_edit_folios: {
        owner: [
          'CREATE_ACCOUNT_FOLIO', 'EDIT_ACCOUNT_FOLIO',
          'CREATE_ACCOUNT_FOLIO_TRANSACTION', 'EDIT_ACCOUNT_FOLIO_TRANSACTION'
        ],
        admin: [
          'CREATE_ACCOUNT_FOLIO', 'EDIT_ACCOUNT_FOLIO',
          'CREATE_ACCOUNT_FOLIO_TRANSACTION', 'EDIT_ACCOUNT_FOLIO_TRANSACTION'
        ],
        manager: [
          'CREATE_PROPERTY_FOLIO', 'EDIT_PROPERTY_FOLIO',
          'CREATE_PROPERTY_FOLIO_TRANSACTION', 'EDIT_PROPERTY_FOLIO_TRANSACTION'
        ],
        staff: []
      },
      settings_add_edit_properties: {
        owner: [
          'CREATE_ACCOUNT_PROPERTY', 'EDIT_ACCOUNT_PROPERTY', 'DELETE_ACCOUNT_PROPERTY',
          'EDIT_ACCOUNT_PROPERTY_CHILDREN_RATES', 'EDIT_ACCOUNT_PROPERTY_WEEKDAYS'
        ],
        admin: [
          'CREATE_ACCOUNT_PROPERTY', 'EDIT_ACCOUNT_PROPERTY', 'DELETE_ACCOUNT_PROPERTY',
          'EDIT_ACCOUNT_PROPERTY_CHILDREN_RATES', 'EDIT_ACCOUNT_PROPERTY_WEEKDAYS'
        ],
        manager: [
          'EDIT_PROPERTY_CHILDREN_RATES', 'EDIT_PROPERTY_WEEKDAYS'
        ],
        staff: []
      },
      settings_current_settings: {
        owner: [
          'EDIT_OWN_USER', 'READ_ACCOUNT_PROPERTY_CHILDREN_RATES', 'READ_ACCOUNT_PROPERTY_WEEKDAYS'
        ],
        admin: [
          'EDIT_OWN_USER', 'READ_ACCOUNT_PROPERTY_CHILDREN_RATES', 'READ_ACCOUNT_PROPERTY_WEEKDAYS'
        ],
        manager: [
          'EDIT_OWN_USER', 'READ_PROPERTY_CHILDREN_RATES', 'READ_PROPERTY_WEEKDAYS'
        ],
        staff: []
      },
      settings_payment_accounts: {
        owner: ['READ_ACCOUNT_PAYMENT_ACCOUNT'],
        admin: ['READ_ACCOUNT_PAYMENT_ACCOUNT'],
        manager: ['READ_ACCOUNT_PAYMENT_ACCOUNT'],
        staff: []
      },
      view_units_and_rates: {
        owner: [
          'READ_ACCOUNT_UNIT', 'READ_ACCOUNT_UNIT_TYPE',
          'READ_ACCOUNT_SEASON', 'READ_ACCOUNT_UNIT_TYPE_RATE', 'READ_ACCOUNT_DISCOUNT'
        ],
        admin: [
          'READ_ACCOUNT_UNIT', 'READ_ACCOUNT_UNIT_TYPE', 'READ_ACCOUNT_SEASON',
          'READ_ACCOUNT_UNIT_TYPE_RATE', 'READ_ACCOUNT_DISCOUNT'
        ],
        manager: [
          'READ_PROPERTY_UNIT', 'READ_PROPERTY_UNIT_TYPE', 'READ_PROPERTY_SEASON',
          'READ_PROPERTY_UNIT_TYPE_RATE', 'READ_PROPERTY_DISCOUNT'
        ],
        staff: []
      },
      change_units_and_rates: {
        owner: [
          'CREATE_ACCOUNT_UNIT', 'EDIT_ACCOUNT_UNIT', 'DELETE_ACCOUNT_UNIT',
          'CREATE_ACCOUNT_UNIT_TYPE', 'EDIT_ACCOUNT_UNIT_TYPE', 'DELETE_ACCOUNT_UNIT_TYPE',
          'CREATE_ACCOUNT_SEASON', 'EDIT_ACCOUNT_SEASON', 'DELETE_ACCOUNT_SEASON',
          'CREATE_ACCOUNT_UNIT_TYPE_RATE', 'EDIT_ACCOUNT_UNIT_TYPE_RATE', 'DELETE_ACCOUNT_UNIT_TYPE_RATE',
          'CREATE_ACCOUNT_DISCOUNT', 'EDIT_ACCOUNT_DISCOUNT', 'DELETE_ACCOUNT_DISCOUNT'
        ],
        admin: [
          'CREATE_ACCOUNT_UNIT', 'EDIT_ACCOUNT_UNIT', 'DELETE_ACCOUNT_UNIT',
          'CREATE_ACCOUNT_UNIT_TYPE', 'EDIT_ACCOUNT_UNIT_TYPE', 'DELETE_ACCOUNT_UNIT_TYPE',
          'CREATE_ACCOUNT_SEASON', 'EDIT_ACCOUNT_SEASON', 'DELETE_ACCOUNT_SEASON',
          'CREATE_ACCOUNT_UNIT_TYPE_RATE', 'EDIT_ACCOUNT_UNIT_TYPE_RATE', 'DELETE_ACCOUNT_UNIT_TYPE_RATE',
          'CREATE_ACCOUNT_DISCOUNT', 'EDIT_ACCOUNT_DISCOUNT', 'DELETE_ACCOUNT_DISCOUNT'
        ],
        manager: [
          'CREATE_PROPERTY_UNIT', 'EDIT_PROPERTY_UNIT', 'DELETE_PROPERTY_UNIT',
          'CREATE_PROPERTY_UNIT_TYPE', 'EDIT_PROPERTY_UNIT_TYPE', 'DELETE_PROPERTY_UNIT_TYPE',
          'CREATE_PROPERTY_SEASON', 'EDIT_PROPERTY_SEASON', 'DELETE_PROPERTY_SEASON',
          'CREATE_PROPERTY_UNIT_TYPE_RATE', 'EDIT_PROPERTY_UNIT_TYPE_RATE', 'DELETE_PROPERTY_UNIT_TYPE_RATE',
          'CREATE_PROPERTY_DISCOUNT', 'EDIT_PROPERTY_DISCOUNT', 'DELETE_PROPERTY_DISCOUNT'
        ],
        staff: []
      },
      view_extras: {
        owner: ['READ_ACCOUNT_EXTRA', 'READ_ACCOUNT_EXTRA_GROUP'],
        admin: ['READ_ACCOUNT_EXTRA', 'READ_ACCOUNT_EXTRA_GROUP'],
        manager: ['READ_PROPERTY_EXTRA', 'READ_ACCOUNT_EXTRA_GROUP'],
        staff: []
      },
      add_edit_extras: {
        owner: ['CREATE_ACCOUNT_EXTRA', 'CREATE_ACCOUNT_EXTRA_GROUP'],
        admin: ['CREATE_ACCOUNT_EXTRA', 'CREATE_ACCOUNT_EXTRA_GROUP'],
        manager: ['CREATE_PROPERTY_EXTRA', 'CREATE_ACCOUNT_EXTRA_GROUP'],
        staff: []
      },
      view_carepackages: {
        owner: ['READ_ACCOUNT_CAREPACKAGE', 'READ_ACCOUNT_CAREPACKAGE_GROUP'],
        admin: ['READ_ACCOUNT_CAREPACKAGE', 'READ_ACCOUNT_CAREPACKAGE_GROUP'],
        manager: ['READ_PROPERTY_CAREPACKAGE', 'READ_ACCOUNT_CAREPACKAGE_GROUP'],
        staff: []
      },
      add_edit_carepackages: {
        owner: ['CREATE_ACCOUNT_CAREPACKAGE', 'CREATE_ACCOUNT_CAREPACKAGE_GROUP'],
        admin: ['CREATE_ACCOUNT_CAREPACKAGE', 'CREATE_ACCOUNT_CAREPACKAGE_GROUP'],
        manager: ['CREATE_PROPERTY_CAREPACKAGE', 'CREATE_ACCOUNT_CAREPACKAGE_GROUP'],
        staff: []
      },

      view_users_admin_manager: {
        owner: ['READ_ACCOUNT_USER'],
        admin: ['READ_ACCOUNT_USER'],
        manager: [],
        staff: []
      },
      add_edit_users_admin_manager: {
        owner: ['CREATE_ACCOUNT_USER', 'EDIT_ACCOUNT_USER'],
        admin: ['CREATE_ACCOUNT_USER', 'EDIT_ACCOUNT_USER'],
        manager: [],
        staff: []
      },
      view_dashboard: {
        owner: ['READ_ACCOUNT_DASHBOARD'],
        admin: ['READ_ACCOUNT_DASHBOARD'],
        manager: ['READ_PROPERTY_DASHBOARD'],
        staff: []
      },
      view_taxes: {
        owner: ['READ_ACCOUNT_TAX'],
        admin: ['READ_ACCOUNT_TAX'],
        manager: ['READ_PROPERTY_TAX'],
        staff: []
      },
      add_edit_taxes: {
        owner: ['CREATE_ACCOUNT_TAX', 'EDIT_ACCOUNT_TAX', 'DELETE_ACCOUNT_TAX'],
        admin: ['CREATE_ACCOUNT_TAX', 'EDIT_ACCOUNT_TAX', 'DELETE_ACCOUNT_TAX'],
        manager: ['CREATE_PROPERTY_TAX', 'EDIT_PROPERTY_TAX', 'DELETE_PROPERTY_TAX'],
        staff: []
      },
      view_housekeeping: {
        owner: ['READ_ACCOUNT_UNIT_HOUSEKEEPING'],
        admin: ['READ_ACCOUNT_UNIT_HOUSEKEEPING'],
        manager: ['READ_PROPERTY_UNIT_HOUSEKEEPING'],
        staff: ['READ_PROPERTY_UNIT_HOUSEKEEPING']
      },
      edit_housekeeping: {
        owner: ['EDIT_ACCOUNT_UNIT_HOUSEKEEPING'],
        admin: ['EDIT_ACCOUNT_UNIT_HOUSEKEEPING'],
        manager: ['EDIT_PROPERTY_UNIT_HOUSEKEEPING'],
        staff: ['EDIT_PROPERTY_UNIT_HOUSEKEEPING']
      },
      view_maintenance: {
        owner: ['READ_ACCOUNT_UNIT_MAINTENANCE'],
        admin: ['READ_ACCOUNT_UNIT_MAINTENANCE'],
        manager: ['READ_PROPERTY_UNIT_MAINTENANCE'],
        staff: ['READ_PROPERTY_UNIT_MAINTENANCE']
      },
      edit_maintenance: {
        owner: ['EDIT_ACCOUNT_UNIT_MAINTENANCE'],
        admin: ['EDIT_ACCOUNT_UNIT_MAINTENANCE'],
        manager: ['EDIT_PROPERTY_UNIT_MAINTENANCE'],
        staff: ['EDIT_PROPERTY_UNIT_MAINTENANCE']
      },
      view_permissions: {
        owner: ['MANAGE_ACCOUNT_PERMISSION'],
        admin: [],
        manager: [],
        staff: []
      },
      view_reports: {
        owner: ['READ_ACCOUNT_STATISTICS'],
        admin: ['READ_ACCOUNT_STATISTICS'],
        manager: ['READ_PROPERTY_STATISTICS'],
        staff: []
      },
      manage_owners: {
        technical_support: [
          'CREATE_ACCOUNT_USER', 'READ_ANY_USER', 'EDIT_ANY_USER', 'DELETE_ANY_USER'
        ],
        customer_support: [
          'CREATE_ACCOUNT_USER', 'READ_ANY_USER', 'EDIT_ANY_USER', 'DELETE_ANY_USER'
        ]
      },
      manage_team_create_superadmin: {
        technical_support: [
          'CREATE_SUPERADMIN_USER', 'CREATE_SUPPORT_USER', 'READ_ANY_USER',
          'EDIT_ANY_USER', 'DELETE_ANY_USER'
        ],
        customer_support: [
          'CREATE_SUPERADMIN_USER', 'CREATE_SUPPORT_USER', 'READ_ANY_USER',
          'EDIT_ANY_USER', 'DELETE_ANY_USER'
        ]
      },
      manage_team_create_support_user: {
        technical_support: [
          'CREATE_SUPPORT_USER', 'READ_ANY_USER',
          'EDIT_ANY_USER', 'DELETE_ANY_USER'
        ],
        customer_support: [
          'CREATE_SUPPORT_USER', 'READ_ANY_USER',
          'EDIT_ANY_USER', 'DELETE_ANY_USER'
        ]
      },
      manage_properties: {
        technical_support: [
          'CREATE_ANY_PROPERTY', 'READ_ANY_PROPERTY', 'EDIT_ANY_PROPERTY',
          'DELETE_ANY_PROPERTY', 'EDIT_ANY_PROPERTY_CHILDREN_RATES', 'EDIT_ANY_PROPERTY_WEEKDAYS'
        ],
        customer_support: [
          'CREATE_ANY_PROPERTY', 'READ_ANY_PROPERTY', 'EDIT_ANY_PROPERTY',
          'EDIT_ANY_PROPERTY_CHILDREN_RATES', 'EDIT_ANY_PROPERTY_WEEKDAYS'
        ]
      },
      manage_withdrawal_countries: {
        technical_support: [
          'CREATE_ANY_WITHDRAWAL_COUNTRIES', 'READ_ANY_WITHDRAWAL_COUNTRIES',
          'EDIT_ANY_WITHDRAWAL_COUNTRIES', 'DELETE_ANY_WITHDRAWAL_COUNTRIES'
        ],
        customer_support: [
          'CREATE_ANY_WITHDRAWAL_COUNTRIES', 'READ_ANY_WITHDRAWAL_COUNTRIES',
          'EDIT_ANY_WITHDRAWAL_COUNTRIES', 'DELETE_ANY_WITHDRAWAL_COUNTRIES'
        ]
      },
      manage_audits_and_reports: {
        technical_support: [
          'CREATE_ANY_AUDIT_OR_REPORT', 'READ_ANY_AUDIT_OR_REPORT',
          'EDIT_ANY_AUDIT_OR_REPORT', 'DELETE_ANY_AUDIT_OR_REPORT'
        ],
        customer_support: [
          'CREATE_ANY_AUDIT_OR_REPORT', 'READ_ANY_AUDIT_OR_REPORT',
          'EDIT_ANY_AUDIT_OR_REPORT', 'DELETE_ANY_AUDIT_OR_REPORT'
        ]
      }
    }
  },
  admin: {
    groups: ['technical_support', 'customer_support']
  }
};
