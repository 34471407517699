import { AuthResponse } from './models';
import { AuthService as CoreAuthService } from '@core/auth/auth.service';
import { Injectable, Injector } from '@angular/core';
// import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '@adminShared/user/models';
import { UserService } from '@adminShared/user/user.service';

@Injectable()
export class AuthService extends CoreAuthService {
  constructor(
    protected injector: Injector,
    private userService: UserService
  ) {
    super(injector);
  }

  public async isAuthenticated(): Promise<boolean> {
    const tokenExists = super.isAuthenticated();
    const userExists = !!(await this.userService.get());

    return tokenExists && userExists;
  }

  public auth(response: AuthResponse): Promise<any> {
    console.log(response);
    // const decodedTokenData = new JwtHelperService().decodeToken(response.token);
    const user = new User(response.user);

    console.log(user);
    if (user.is_superuser) {
      return super
        .auth(response)
        .then(() => {
          return this.userService.saveInStorage(user);
        });
    } else {
      return Promise.reject({ errors: { server: { is_not_super_user: true } } });
    }
  }

  public logout(): Promise<any> {
    return super
      .logout()
      .then(() => {
        return this.userService.removeUserFromStorage();
      });
  }
}
