import { ApiModule } from '../../core/api/api.module';
import { HasPermissionGuard } from './guards';
import { NgModule } from '@angular/core';
import { PermissionService } from './permission.service';

@NgModule({
  imports: [
    ApiModule
  ],
  providers: [
    PermissionService,
    HasPermissionGuard
  ]
})
export class PermissionModule { }
