import * as lodash from 'lodash';
import { ApiService } from '../../core/api/api.service';
import { BusinessType } from './models';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class BusinessTypeService {
  constructor(
    private apiService: ApiService,
    private translateService: TranslateService
  ) { }

  public getAll(): Observable<Array<BusinessType>> {
    return this.apiService
      .get<Array<BusinessType>>('/business-types')
      .pipe(
        map((businessTypes) => businessTypes.map((businessType) => new BusinessType(businessType))),
        tap({
          next: (businessTypes) => {
            return businessTypes.map(async (businessType) => {
              const translatedName = await this.translateService
                .get(`BUSINESS_TYPES.TEXT_${lodash.snakeCase(businessType.name).toUpperCase()}`)
                .toPromise();

              if (translatedName) {
                businessType.name = translatedName;
              }

              return businessType;
            });
          }
        })
      );
  }
}
