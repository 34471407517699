import { FiltersService } from './filters.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    RouterModule
  ],
  providers: [
    FiltersService
  ]
})
export class FiltersModule { }
