import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 75]
};

const routes: Routes = [
  { path: '', loadChildren: 'app/public/public.module#PublicModule' },
  { path: '', loadChildren: 'app/account/account.module#AccountModule' },
  { path: 'admin', loadChildren: 'app/admin/admin.module#AdminModule' },
  { path: '**', loadChildren: 'app/shared/notFound/notFound.module#NotFoundPageModule' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
