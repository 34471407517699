import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.html',
  styleUrls: ['./pagination.scss']
})
export class PaginationComponent implements OnInit {
  @Input() id: string;
  @Output('pageChange') pageChangeEventEmitter: EventEmitter<number>;

  public previousLabel: string;
  public nextLabel: string;
  public screenReaderPaginationLabel: string;
  public screenReaderPageLabel: string;
  public screenReaderCurrentLabel: string;

  constructor(
    private translateService: TranslateService
  ) {
    this.pageChangeEventEmitter = new EventEmitter();
  }

  public ngOnInit(): void {
    this.translateService
      .get('PAGINATION')
      .pipe(
        take(1)
      )
      .subscribe((values) => {
        this.previousLabel = values['TEXT_PREVIOUS'];
        this.nextLabel = values['TEXT_NEXT'];
        this.screenReaderPaginationLabel = values['TEXT_SCREEN_READER_LABEL'];
        this.screenReaderPageLabel = values['TEXT_SCREEN_READER_PAGE_LABEL'];
        this.screenReaderCurrentLabel = values['TEXT_SCREEN_READER_CURRENT_LABEL'];
      });
  }

  public pageChange(page: number): void {
    this.pageChangeEventEmitter.emit(page);
  }
}
