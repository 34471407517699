export class PermissionGroup {
  public id: number;
  public name: string;
  public has: boolean;
  public hidden?: boolean;
  public disabled?: boolean;

  constructor(permissionGroup: any = {}) {
    this.id = permissionGroup.id || null;
    this.name = permissionGroup.name || '';
    this.has = !!permissionGroup.has;
    this.hidden = !!permissionGroup.hidden;
    this.disabled = !!permissionGroup.disabled;
  }
}
