import * as lodash from 'lodash';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { User } from '../../user/models';
import { UserService } from '../../user/user.service';

@Injectable()
export class HasPermissionGuard implements CanActivate {
  private list: { [routeName: string]: Array<string> | { [routeName: string]: Array<string> } } = {
    'dashboard': ['view_dashboard'],
    'bookings': ['view_calendar_bookings'],
    'calendar': ['view_calendar_bookings'],
    'housekeeping': ['view_housekeeping'],
    'maintenance': ['view_maintenance'],
    'extras': ['view_extras'],
    'carepackages': ['view_carepackages'],
    'taxes': ['view_taxes'],
    'folios': ['view_folios'],
    'units': ['view_units_and_rates'],
    'users': ['view_users_admin_manager'],
    'reports': ['view_reports'],
    ':id': {
      'bookings': ['view_calendar_bookings', 'add_edit_bookings'],
      'folios': ['view_folios']
    },
    'admin': {
      'owners': ['manage_owners'],
      'team': ['manage_team_create_support_user'],
      'properties': ['manage_properties']
    }
  };

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const user = await this.userService.get();
    const hasPermission = this.hasPermission(user, route);
    // const hasPermission = true;

    if (!hasPermission) {
      this.router.navigate(['/account-settings']);
    }

    return hasPermission;
  }

  private hasPermission(user: User, route: ActivatedRouteSnapshot): boolean {
    if (user.is_superuser && user.group.name === 'superadmin') {
      return true;
    } else {
      const permissions = (route.parent.parent.parent.routeConfig.path === 'admin')
        ? this.list.admin[route.routeConfig.path]
        : this.list[route.routeConfig.path];

      if (lodash.isArray(permissions)) {
        return this.checkPermissions(user, permissions as Array<string>);
      } else if (lodash.isPlainObject(permissions)) {
        return this.hasPermission(user, route.parent);
      }

      return false;
    }
  }

  private checkPermissions(user: User, permissions: Array<string>): boolean {
    for (const permission of permissions) {
      if (!user.hasPermission(permission)) {
        return false;
      }
    }

    return true;
  }
}
