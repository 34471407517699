import { ApiModule } from '@core/api/api.module';
import { DiscountService } from './discount.service';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    ApiModule
  ],
  providers: [
    DiscountService
  ]
})
export class AccountSharedDiscountModule { }
